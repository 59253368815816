
import Vue from 'vue';
import { mapActions } from 'vuex';
import VueI18n from '@/i18n';

export default Vue.extend({
  name: 'LoginForm',
  data() {
    return {
      username: '',
      password: '',
      formValid: true,
      usernameRules: [
        (v: string) => !!v || VueI18n.t('loginPage.usernameIsRequired'),
      ],
      passwordRules: [
        (v: string) => !!v || VueI18n.t('loginPage.passwordIsRequired'),
      ],
    };
  },
  methods: {
    ...mapActions('auth', ['login', 'getMe']),
    async onLoginSubmit() {
      await this.login({ username: this.username, password: this.password });
      await this.getMe()
      this.$router.push('/');
    },
  },
});
