
import Vue from 'vue';
import LoginForm from './LoginForm.vue';

export default Vue.extend({
  name: 'LoginPage',
  components: {
    LoginForm,
  },
});
